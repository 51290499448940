import React from 'react';
import { Heading } from '@atoms';
import { wordTime } from '@utils/wordTime';
import { publishDateFormat } from '@utils/text';
import {  Link } from 'gatsby';
import { 
  HeroContainer, 
  Blur, 
  TextContentContainer, 
  BreadCrumbFlex, 
  CategoriesFlex, 
  CategoryTag, 
  DetailTextFlex, 
  MobileFeaturedImage, 
  IconImg 
} from './styles/HeroBlogDetail.styled';
import OptimizedImage from '../../utils/optimizedImage';

export const HeroBlogDetail = ({ postData }) => {
  return (
    <HeroContainer
      data-comp={HeroBlogDetail.displayName}
    >
      <OptimizedImage className="desktop-hero" loading="eager" image={postData?.featuredImage?.gatsbyImageData} src={postData?.featuredImage?.file?.url} alt=""/>
      <BreadCrumbFlex>
        <Link href="/"><span>Home</span></Link>
        <IconImg>
          <img src='/icons/chevron-right-white.svg' alt="Breadcrumb Chevron"/>
          <img src='/icons/chevron-right-black.svg' alt="Breadcrumb Chevron"/>
        </IconImg>
        <Link href="/blog"><span>Blog</span></Link>
        <IconImg>
          <img src='/icons/chevron-right-white.svg' alt="Breadcrumb Chevron"/>
          <img src='/icons/chevron-right-black.svg' alt="Breadcrumb Chevron"/>
        </IconImg>
        <span>{postData.externalTitle || postData.title }</span>
      </BreadCrumbFlex>
      <TextContentContainer>
        <CategoriesFlex>
          { postData.category &&
            postData.category.map(category => {
              return (
                <CategoryTag style={{ backgroundColor: category?.backgroundColor, color: category?.fontColor || '#1B1B1B', }}>{category?.title}</CategoryTag>
              )
            })
          }
        </CategoriesFlex>
        <Heading
            background='dark'
            headingSize='H2'
            style={{
              paddingBottom: '8px',
              maxWidth: '670px'
            }}
          >
          {postData.externalTitle || postData.title}
        </Heading>
        <DetailTextFlex>
          <div className="hero-blog-detail--detail-text-item">
            <IconImg className="hero-blog-detail--detail-text-item-icon">
              <img src='/icons/clock-white.svg' alt="Read Time Icon Light"/>
              <img src='/icons/clock-black.svg' alt="Read Time Icon Dark"/>
            </IconImg>
            <span>{wordTime(postData?.body?.raw)} read time</span>
          </div>
          <div className="hero-blog-detail--detail-text-item">
            <IconImg className="hero-blog-detail--detail-text-item-icon">
              <img src='/icons/small-calendar-white.svg' alt="Publish Date Icon Light"/>
              <img src='/icons/small-calendar-black.svg' alt="Publish Date Icon Dark"/>
            </IconImg>
            <span>Published on {publishDateFormat(postData?.publishDate)}</span>
          </div>
          <div className="hero-blog-detail--detail-text-item">
            {postData.author && <IconImg className="hero-blog-detail--detail-text-item-icon">
              <img src='/icons/small-profile-white.svg' alt="Author Icon Light"/>
              <img src='/icons/small-profile-black.svg' alt="Author Icon Dark"/>
            </IconImg>}
            {postData?.author && postData?.author[0] ? <span>Written by {postData?.author[0]?.fullName}</span> : ''}
          </div>
        </DetailTextFlex>
        {/* <Link style={{ textDecoration: 'none' }} href="/"><HomeCrumb id='blog-listing--home-crumb' style={{ textDecoration: 'none', color: 'white', transition: 'color 200ms ease' }}>Home</HomeCrumb></Link>
        <img src='/icons/chevron-right-white.svg' />
        <div style={{ fontWeight: 700, cursor: 'pointer' }}>Blog</div> */}
      </TextContentContainer>
      <Blur />
      <MobileFeaturedImage>
        <OptimizedImage className="mobile-hero" loading="eager" image={postData?.featuredImage?.gatsbyImageData} src={postData?.featuredImage?.file?.url} alt=""/>
      </MobileFeaturedImage>
    </HeroContainer>
  );
};

HeroBlogDetail.displayName = 'HeroBlogDetail';
